<template>
  <div class="njServe">
    <div class="topImg"></div>
    <Title
      class="njServiceTitle"
      njTitle="NjImg"
      typeOf="njImgs"
      :title="address + '数字农机'"
    ></Title>

    <div class="ContentBox">
      <!-- 左侧模块 -->
      <div class="ContentLeft">
        <!-- 农机左侧模块顶部 -->
        <MkContent titleSrcName="njTitle" titleName="基本信息">
          <div class="njTopBox">
            <div
              v-for="v in stateData"
              :key="v.id"
              class="njOne"
              @click="showDetail(v.id, 'ddd')"
            >
              <img :src="v.srcName" alt="" />
              <div style="display: flex; flex-direction: column">
                <div class="njOne-top">
                  <div class="njOneFontL">{{ v.data }}</div>
                  <div class="njOneFont">&nbsp;{{ v.unit }}</div>
                </div>
                <div class="njOne-bottom">{{ v.introduce }}</div>
              </div>
            </div>
          </div>
        </MkContent>
        <!-- 农机左侧模块底部 -->
        <MkContent
          titleSrcName="njTitle"
          titleName="农机类型"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <LeftCont :propOptions="njzl" @click="click"></LeftCont>
            <LeftCont :propOptions="jzbd"></LeftCont>
          </div>
        </MkContent>
      </div>
      <!-- 中间部分 -->
      <div class="ContentMiddle">
        <MidBox
          v-for="v in MidData"
          :key="v.id"
          :boxData="v"
          style="margin: 0 companyW(8vw)"
          @changeMaskd="changeMas"
        ></MidBox>
        <TMap class="tMap" v-on="$listeners" v-bind="$attrs"></TMap>
      </div>

      <!-- 右侧部分 -->
      <div class="ContentRight">
        <MkContent titleSrcName="njTitle" titleName="农机状态">
          <div class="njTopBox">
            <div
              v-for="v in njstate"
              :key="v.id"
              class="njTopRight"
              @click="showDetail(v.id, 'right')"
            >
              <div class="ztText">{{ v.data }}</div>
              <img :src="v.logoSrc" alt="" />
              <div class="ztText">{{ v.title }}</div>
            </div>
            <img src="@/assets/njImgs/line.png" alt="" class="rightLine" />
          </div>
        </MkContent>

        <MkContent
          titleSrcName="njTitle"
          titleName="购机补贴"
          style="margin-top: 20px"
        >
          <div class="njTopBox" style="padding: 0">
            <el-select v-model="currentTime" @change="ljYearChange">
              <el-option
                v-for="item in TimeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <RightCont :propOptions="gjbtPie" @click="click"></RightCont>
            <RightCont :propOptions="gjbtBar"></RightCont>
          </div>
        </MkContent>
      </div>
    </div>
    <BottomNav
      :navData="btData"
      class="BottomNav"
      njTitle="navBottomImg"
      typeOf="njImgs"
    ></BottomNav>
    <MaskBox
      :changeMask="showMask"
      @getMask="closeMask"
      :NavList="NavList"
      :TanleContent.sync="TanleContent"
      :currentId.sync="currentId"
      :uid.sync="uid"
      :currentInd.sync="currentInd"
    >
      <MidChart v-if="isJbxx" :propOptions="toolnj"></MidChart>
      <NjMaskImg v-else :stateParams="stateParams" :uid="uid"></NjMaskImg>
    </MaskBox>

    <MaskToolNJs
      :visible.sync="visible"
      @getNjsInfoEmit="getNJsInfo"
      :TanleContent.sync="TanleContent"
      :total.sync="total"
      :queryObj="queryObj"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange1="handleCurrentChange1"
    >
      <MidChart :propOptions="toolnj"></MidChart>
    </MaskToolNJs>
    <NjddInfo
      :visible.sync="visibleInfo"
      :TanleContent.sync="TanleContent"
      @getFinOrder="getFinOrder"
      :total.sync="total"
      :queryObj="queryObj"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></NjddInfo>
  </div>
</template>

<script>
import LeftCont from './components/njzl/LeftContent.vue';
import RightCont from './components/njzl/RightContent.vue';
// 每页标头
import Title from '@/views/drying/components/title.vue';
import MkContent from '@/views/njall/components/ModelBg.vue';
// 中间每个小标题
import MidBox from '@/views/njall/components/MiddleBox.vue';
// 底部导航栏
import BottomNav from '@/views/njall/components/BottomNav.vue';
// 提示蒙层
import MaskBox from '@/views/njall/components/MaskTool.vue';
import MaskToolNJs from '@/views/njall/components/MaskToolNJs.vue';
import NjMaskImg from '@/views/njall/components/njzl/NjzlMaskImg.vue';
import MidChart from '@/views/njall/components/njzl/ToolChart.vue';
import NjddInfo from '@/views/njall/components/MaskToolInfo.vue';
// mock数据
import * as mockData from '@/views//njall/mock/njJson.js';
import * as njlxmock from '@/views/njall/mock/njlxmock.js';
import * as options from '@/views/njall/mock/njToolMock.js';
import TMap from '@/views/njall/components/TMap/njzlMap.vue';

//api
import {
  getIndexDetail,
  getIndexType,
  getIndexBt,
  getIndexNJcount,
  getIndexNJState,
  getIndexBFxz,
  getIndexNjs,
  getNOFin
} from '@/api/njApi/njzl.js';
import { getMain } from '@/api/home/index.js';
export default {
  components: {
    BottomNav,
    MidChart,
    NjMaskImg,
    Title,
    LeftCont,
    RightCont,
    MkContent,
    MidBox,
    MaskBox,
    MaskToolNJs,
    NjddInfo,
    TMap
  },
  mounted () {
    this.address = localStorage.getItem('address');
    this.getType();

    this.getBt(2024);
  },
  created () {
    this.getMain();

    this.getIndexData();
    let year = new Date().getFullYear();
    this.currentTime = year;
    this.TimeOptions = [];
    for (let i = 0; i < 4; i++) {
      this.TimeOptions.unshift({
        value: year - i,
        label: (year - i).toString()
      });
    }
  },
  methods: {
    click (item) {
      if (item.data.name === '耕整机') {
        this.currentInd = 1;
        this.getIndexNJDIa(1, '耕整机', 'kw');
      } else if (item.data.name === '插秧机') {
        this.getIndexNJDIa(1, '插秧机', 'kw');

        this.currentInd = 2;
      } else if (item.data.name === '收割机') {
        this.getIndexNJDIa(1, '收割机', 'kw');

        this.currentInd = 3;
      } else if (item.data.name === '植保机') {
        this.getIndexNJDIa(1, '植保机', 'kw');

        this.currentInd = 4;
      } else if (item.data.name === '烘干机械') {
        this.getIndexNJDIa(1, '烘干机械', 'kw');

        this.currentInd = 5;
      } else if (item.data.name === '加工机械') {
        this.getIndexNJDIa(1, '加工机械', 'kw');

        this.currentInd = 6;
      }

      this.showDetail(1, 'left');
    },
    async getMain () {
      const { data } = await getMain({ status: 'yearNum' });


      this.jzbd.xAxis.data = data
        .filter(item => {
          return (
            item.job_type !== '烘干' &&
            item.job_type !== '加工' &&
            item.job_type !== '育秧'
          );
        })
        .map(item => {
          return item.job_type.split(',').join();
        });

      this.jzbd.series[0].data = data
        .filter(item => {
          return (
            item.job_type !== '烘干' &&
            item.job_type !== '加工' &&
            item.job_type !== '育秧'
          );
        })
        .map(item => {
          return item.count;
        });

      this.jzbd.series[0].name = '各作业工作面积(亩)';
    },

    showDetail (id, typeofs) {
      if (typeofs == 'left') {
        this.isJbxx = 'jbxx';

        if (id === 1) {
          this.getIndexNJDIa(1, '', '台');
          if (this.currentInd === null) {
            this.getIndexNJDIa(1, '合计', 'kw');
          }

          this.toolnj.title.text = '农机总量';
          this.currentId = id;
          this.showMask = true;
        } else if (id === 2) {
          this.getIndexNJDIa('car_power', '', 'kw');
          this.getIndexNJDIa('car_power', '合计', 'kw');
          this.toolnj.title.text = '农机总动力';
          this.currentId = id;
          this.showMask = true;
        } else if (id === 3) {
          this.getIndexNJDIa('price', '', '元');
          this.getIndexNJDIa('price', '合计', '元');
          this.toolnj.title.text = '农机原值';
          this.currentId = id;
          this.showMask = true;
        } else {
          this.getIndexNJDIa('allowance', '', '元');
          this.getIndexNJDIa('allowance', '合计', '元');
          this.toolnj.title.text = '购机补贴';
          this.currentId = id;
          this.showMask = true;
        }
      } else if (typeofs == 'right') {
        this.isJbxx = '';
        if (id === 1) {
          this.uid = id;
          this.getIndexStatea('正常');
          this.getIndexStatea('正常', '耕整机');
          this.stateParams.title = '正常';
          this.stateParams.count = this.stateNum;
          this.showMask = true;
        } else if (id === 2) {
          this.uid = id;
          this.getIndexStatea('损坏', '耕整机');
          this.getIndexStatea('损坏');
          this.stateParams.title = '损坏';
          this.stateParams.count = this.stateNum1;
          this.showMask = true;
        } else if (id === 3) {
          this.uid = id;
          this.getIndexStatea('报废');
          this.getIndexStatea('报废', '耕整机');

          this.stateParams.title = '报废';
          this.stateParams.count = this.stateNum2;
          this.showMask = true;
        } else if (id === 4) {
          this.uid = id;
          this.stateParams.title = '在作业';
          this.getIndexStatea('工作', '耕整机');
          this.getIndexStatea('工作');
          this.stateParams.count = this.stateNum3;
          this.showMask = true;
        } else if (id === 5) {
          this.uid = id;
          this.getIndexStatea('空闲', '耕整机');
          this.getIndexStatea('空闲');
          this.stateParams.title = '待作业';
          this.stateParams.count = this.stateNum4;
          this.showMask = true;
        } else {
          this.uid = id;
          this.getIndexStatea('');
          this.getIndexStatea('近三年新增农机');

          this.stateParams.title = '近三年新增或报废';
          this.stateParams.count = this.stateNum5;
          this.showMask = true;
        }
      } else if (id === 1) {
        this.isJbxx = 'jbxx';

        this.getIndexNJDIa(1, '', '台');
        this.getIndexNJDIa(1, '合计', 'kw');

        this.toolnj.title.text = '农机总量';
        this.currentId = id;
        this.showMask = true;
      } else if (id === 2) {
        this.getNJsInfo(0, '中心自有机手');

        this.getNJsInfo1();
        this.visible = true;
        this.toolnj.title.text = '农机手';
      } else if (id === 3) {
        this.getFinOrder('已完成', '');

        this.visibleInfo = true;
      } else {
        this.getFinOrder('已完成', '');
        this.visibleInfo = true;
      }

      this.currentInd = 0;
    },
    closeMask (item) {
      this.showMask = item;
    },

    changeMas (params) {
      this.showDetail(params.id, params.typeof);
    },

    async getIndexData () {
      const { data } = await getIndexDetail();
      this.stateData[0].data = data.carCount;
      this.stateData[1].data = data.operatorCount;
      this.stateData[2].data = Math.floor(data.completedArea);
      this.stateData[3].data = data.completedCount;
      this.MidData[0].data = data.carCount;
      this.MidData[1].data = data.allPower;
      this.MidData[2].data = data.allPrice;
      this.MidData[3].data = data.allAllowance;
      this.njstate[0].data = data.normalCount;
      this.njstate[1].data = data.damageCount;
      this.njstate[2].data = data.scrapCount;
      this.njstate[3].data = data.workCount;
      this.njstate[4].data = data.freeCount;
      this.njstate[5].data = data.threeYearAddCount;
      this.stateNum = data.normalCount;
      this.stateNum1 = data.damageCount;
      this.stateNum2 = data.scrapCount;
      this.stateNum3 = data.workCount;
      this.stateNum4 = data.freeCount;
      this.stateNum5 = data.threeYearAddCount;

    },
    async getType () {
      const { data } = await getIndexType();

      this.njzl.xAxis.data = data.map(item => {
        return item.car_type.split(',').join();
      });
      this.njzl.series[0].data = data.map(item => {
        return item.COUNT;
      });

      const res = data
        .map(item => {
          return item.COUNT;
        })
        .reduce((item, prev) => {
          return (item += prev);
        }, 0);
      this.njzl.series[0].name = `总数：${ res }台`;
    },
    ljYearChange (e) {
      this.getBt(e);
    },
    async getBt (e) {
      const { data } = await getIndexBt({ year: e });

      this.gjbtPie.title.text = data[data.length - 1].allowance;

      this.gjbtPie.series[0].data = data
        .filter(item => {
          return (
            item.car_type !== '合计' &&
            item.car_type !== '烘干机械' &&
            item.car_type !== '加工机械' &&
            item.car_type !== '拖拉机'
          );
        })
        .map(item => {
          return {
            count: item.amountCount,
            name: item.car_type,
            value: item.count
          };
        });

      //购机补贴柱状图
      this.gjbtBar.xAxis.data = data
        .filter(item => {
          return (
            item.car_type !== '合计' &&
            item.car_type !== '烘干机械' &&
            item.car_type !== '加工机械' &&
            item.car_type !== '拖拉机'
          );
        })
        .map(item => {
          return item.car_type.split(',').join();
        });
      this.gjbtBar.series[0].data = data
        .filter(item => {
          return (
            item.car_type !== '合计' &&
            item.car_type !== '烘干机械' &&
            item.car_type !== '加工机械' &&
            item.car_type !== '拖拉机'
          );
        })
        .map(item => {
          return item.allowance;
        });
    },

    async getIndexNJDIa (type, carType, unit) {
      const { data } = await getIndexNJcount({ type, carType, unit });

      if (!carType) {
        this.NavList = data;
      }

      if (carType) {
        this.TanleContent = data;
      }
      if (!carType) {
        let res = data
          .filter(item => {
            return item.car_type === '合计';
          })
          .map(item => {
            return item.count.substr(0, item.count.length - 1);
          });

        this.toolnj.series[0].data = data
          .filter(item => {
            return item.car_type !== '合计';
          })
          .map(item => {
            return {
              name: item.car_type,
              value: item.count?.substr(0, item.count.length - 1),
              count: +res
            };
          });
        if (this.currentId === 2) {
          let res = data
            .filter(item => {
              return item.car_type === '合计';
            })
            .map(item => {
              return item.count.substr(0, item.count.length - 2);
            });

          this.toolnj.series[0].data = data
            .filter(item => {
              return item.car_type !== '合计';
            })
            .map(item => {
              return {
                name: item.car_type,
                value: +item.count?.substr(0, item.count.length - 2),
                count: +res
              };
            });
        }
      }
    },
    async getIndexStatea (status, carType) {
      const { data } = await getIndexNJState({ status, carType });

      if (!carType) {
        this.NavList = data
          .filter(item => {
            return item.car_type !== '合计';
          })
          .map(item => {
            return {
              car_type: item.car_type,
              count: `${ item.count }台`
            };
          });
      }
      if (carType) {
        this.TanleContent = data;
      }
      if (this.uid === 6) {
        const res = await getIndexBFxz({ status });
        if (status === '') {
          this.NavList = res.data;
        }
        if (status) {
          this.TanleContent = res.data;
        }
      }
    },
    async getNJsInfo (id, identityType) {
      const { data } = await getIndexNjs({ ...this.queryObj, identityType });



      this.TanleContent = data.records;
      this.total = data.total;

    },
    async   getNJsInfo1 () {

      const { data } = await getIndexNjs({ ...this.queryObj, identityType:'中心自有机手' });
      const res = await getIndexNjs({ ...this.queryObj, identityType:'独立机手' });

      let list3 = [];
      let list1 = {
        name: '中心自有机手',
        value: data.records.length,
        count: data.records.length
      };
      let list2 = {
        name: '跨区机手',
        value: res.data.records.length,
        count: res.data.records.length||1
      };
      list3.push(list1, list2);
      this.toolnj.series[0].data = list3;
    },
    async getFinOrder (status, jobType) {
      const { data } = await getNOFin({ status, jobType, ...this.queryObj });

      this.TanleContent = data.records;
      this.total = data.total;
    },
    handleCurrentChange (item, name) {

      this.queryObj.pageNum = item;
      let res = '';
      if (name === '全部') {
        res = '';
      } else {
        res = name;
      }
      this.getFinOrder('已完成', res);
    },
    handleCurrentChange1 (item) {

      this.queryObj.pageNum = item;

    },
    handleSizeChange (item) {

      this.queryObj.pageSize = item;

    }
  },
  data () {
    return {
      address: '',
      dataRecords: {},
      stateParams: {
        title: '正常',
        count: 46
      },
      total: null,
      queryObj: {
        pageSize: 10,
        pageNum: 1
      },
      currentInd: null,
      stateNum: null,
      stateNum1: null,
      stateNum2: null,
      stateNum3: null,
      stateNum4: null,
      stateNum5: null,
      isJbxx: 'jbxx',
      uid: null,
      currentId: null,
      ...options,
      ...njlxmock,
      showMask: false,
      ...mockData,
      TanleContent: null,
      currentTime: '2023',
      visible: false,
      visibleInfo: false,
      TimeOptions: [
        {
          value: '2023',
          label: '2023'
        },
        {
          value: '2022',
          label: '2022'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #fff;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep [data-v-7ce2d5fc] .el-input__inner {
  width: companyW(85vw);
  height: companyH(40vh);
  font-size: companyW(16vw);
  line-height: companyH(40vh);
}
::v-deep .el-input__suffix {
  right: 0;
  top: companyH(-8vh);
}
.BottomNav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.njServe {
  position: relative;
  background: #1d2531;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;

  // z-index: 1;

  .njServiceTitle {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .ContentBox {
    max-width: companyW(1880vw);
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    .ContentMiddle {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: companyH(40vh) companyW(12vw);
      .tMap {
        margin-top: companyH(70vh);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 2%;
      }
    }
    .ContentRight {
      width: companyW(460vw);
    }
  }
}
.njTopBox {
  box-sizing: border-box;
  width: companyW(460vw);
  // margin-top: companyH(-12vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  background-color: rgba($color: #03171f, $alpha: 0.7);
  position: relative;
  .njOne {
    width: companyW(190vw);
    height: companyH(78vh);
    margin: companyH(30vh) companyW(20vw);
    display: flex;
    align-items: center;
    background: #00111c;
    cursor: pointer;
    img {
      width: companyW(39vw);
      height: companyH(39vh);
      margin: companyH(35vh) companyW(20vw);
    }
    .njOne-top {
      max-width: companyW(190vw);
      display: flex;
      align-items: center;
      .njOneFontL {
        max-width: companyW(62.8vw);
        //   overflow: hidden;
        font-size: companyH(30vh);
        font-weight: bold;
        color: #f0f6f5;
        line-height: companyH(17.2vh);
      }
      .njOneFont {
        font-size: companyH(20vh);
        font-weight: Regular;
        color: #f0f6f5;
        line-height: companyH(17.2vh);
      }
    }
    .njOne-bottom {
      font-size: companyH(14vh);
      font-weight: 400;
      color: #b4c1bf;
      margin-top: companyH(9.2vh);
      line-height: companyH(20vh);
    }
  }
  .rightLine {
    position: absolute;
    width: companyW(438vw);
    height: companyH(3vh);
    top: companyH(192vh);
    left: 0;
  }
  .njTopRight {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: companyH(10vh) companyW(15vw);
    img {
      min-width: companyW(120vw);
      height: companyH(69vh);
    }
    .ztText {
      max-width: companyW(140vw);
      overflow: hidden;
      font-size: companyH(20vh);
      font-weight: 400;
      color: #fff;
      line-height: companyH(27vh);
      margin: companyH(12vh) 0;
    }
  }
}
.el-select {
  position: absolute;
  right: companyW(36vw);
  top: companyH(20vh);
  z-index: 3;
  ::v-deep .el-input {
    width: companyW(76vw);
  }
}
::v-deep .el-input__inner {
  height: companyH(27.4vh);
  background: rgba(6, 40, 62, 0.9);
  border-radius: 0;
  border: #00def0 companyW(1vw) solid;
  font-size: companyH(11.35vh);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e1eaea;
}
::v-deep .el-input__suffix-inner {
  position: relative;
  top: companyH(9vh);
}
:global(.el-select-dropdown) {
  width: companyW(76vw);
  background: rgba(6, 40, 62, 0.9);
}
:global(.el-select-dropdown__item span) {
  font-size: companyH(13vh);
}
</style>

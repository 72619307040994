<template>
  <div>
    <el-dialog
      :visible="ImgDiaVisible"
      :show-close="false"
      @close="closeImg"
      v-if="ImgDiaVisible"
    >
      <dv-border-box-1>
        <div class="ToolBox">
          <div class="title">查看证照信息</div>
          <div class="close" @click="closeImg"></div>
          <el-image
                    style="width:700px; height: 450px"
                    :src="photoSrc"
                  ></el-image>

        </div>

      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: {
    ImgDiaVisible: {
      type: Boolean,
      default: false
    },

    photoSrc:{
      type:String
    }

  },
  data () {
    return {


      tabLoading: false,
      currentId: 0,
      currentName: ''
    };
  },
  methods: {
    closeImg () {
      this.$emit('update:ImgDiaVisible', false);
    },




  },

  created () {

  },
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(700vw);
  background: #000000;
  opacity: 1;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.ToolBox {
  width: 95%;
  height: companyH(700vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;
  justify-content: center;
  .title {
    position: absolute;
    top: companyH(20vh);
    left: 45%;
    font-size: companyW(24vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1dfcff;
  }

  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
}
</style>

<template>
  <div>
    <el-dialog
      :visible="visible"
      :show-close="false"
      @close="closenjs"
      v-if="visible"
    >
      <dv-border-box-1>
        <div class="ToolBox">
          <div class="close" @click="closenjs"></div>
          <slot></slot>
          <div class="NavList" v-if="NavList.length !== 0">
            <div
              class="flexJt"
              v-for="(item, index) in NavList"
              :key="item.id"
              @click="changeNav(index, item.car_type)"
            >
              <div
                :class="index == currentInd ? `NavOne currentNav` : `NavTwo`"
              >
                <div class="NavTitle">{{ item.car_type }}</div>
                <div class="NavTitle2">
                  <span>{{ item.count }}</span>
                </div>
              </div>
              <div class="littleJt" v-if="currentInd == index"></div>
            </div>
          </div>
          <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="TanleContent"
            style="width: 100%"
            height="500"
          >
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="name" label="操作手" align="center">
            </el-table-column>
            <el-table-column
              prop="telphone"
              label="联系方式"
              align="center"
              width="160"
            >
            </el-table-column>
            <el-table-column prop="age" label="年龄" align="center" width="160">
              <template v-slot="{ row }">
                <span style="font-size: 20px">{{ row.age }}{{ '岁' }}</span>
              </template>
            </el-table-column>

            <el-table-column label="驾驶证信息" width="120 " align="center">
              <template v-slot="{ row }">
                <div @click="openDiaImg(row)">查看</div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="completedArea"
              label="已作业面积"
              width="120"
              align="center"
            >
              <template v-slot="{ row }">
                <div>{{ row.completedArea }}亩</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="completedCount"
              label="作业次数"
              align="center"
            >
              <template v-slot="{ row }">
                <div>{{ row.completedCount }}次</div>
              </template>
            </el-table-column> -->

            <el-table-column
              prop="completedArea"
              label="已作业亩数"
              width="120"
              align="center"
            >
              <template v-slot="{ row }">
                <div>{{ row.completedArea }}亩</div>
              </template>
            </el-table-column>
            <el-table-column prop="evaluate" label="综合评价" align="center">
              <template v-slot="{ row }">
                <el-rate
                  v-model="row.evaluate"
                  :disabled="true"
                  :colors="['#009ad6', '#009ad6', '#009ad6']"
                  disabled-void-color="#77787b"
                ></el-rate>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange1"
          :current-page="queryObj.pageNum"
          :page-size="queryObj.pageSize"
          layout="total,prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </dv-border-box-1>
    </el-dialog>
    <ImgDia :ImgDiaVisible.sync="ImgDiaVisible" :photoSrc="photoSrc"></ImgDia>
  </div>
</template>

<script>
import ImgDia from '@/views/njall/components/njdd/njddDiaImg.vue';

export default {
  components: {
    ImgDia
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    TanleContent: {
      type: Array,
      default: () => [],
      require: true
    },
    total: {
      type: Number
    },
    queryObj: {
      type: Object
    }
  },
  data () {
    return {
      tabLoading: true,
      id: 0,
      currentInd: 0,
      rateColor: [ '#009ad6' ],
      photoSrc: '',
      ImgDiaVisible: false,
      NavList: [
        {
          car_type: '中心自有机手'
        },
        {
          car_type: '跨区机手'
        }
      ]
    };
  },
  methods: {
    closenjs () {
      this.$emit('update:visible', false);
    },

    changeNav (id) {
      this.currentInd = id;
      this.getNjs(id);
      this.$emit('handleCurrentChange1', 1);

      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    getNjs (id) {
      this.id = id;
      if (id === 1) {
        this.$emit('getNjsInfoEmit', 1, '独立机手');
      } else {
        this.$emit('getNjsInfoEmit', 0, '中心自有机手');
      }
    },
    handleSizeChange (val) {
      this.$emit('handleSizeChange', val);
    },
    handleCurrentChange1 (val) {
      this.$emit('handleCurrentChange1', val);
      if (this.id === 1) {
        this.$emit('getNjsInfoEmit', 1, '独立机手');
      } else {
        this.$emit('getNjsInfoEmit', 0, '中心自有机手');
      }
    },
    openDiaImg (row) {
      this.photoSrc = row.photo;
      this.ImgDiaVisible = true;
    }
  },

  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(1700vw);
  background: #000000;
  opacity: 1;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-table td.el-table__cell div {
  font-size: companyW(20vw);
}
.ToolBox {
  width: 95%;
  // height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;
  .leftDia {
    width: 55%;
  }
  .leftDia1 {
    width: 50%;
  }
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(30vw);
  }
  .NavOne {
    background-image: url(../../../assets/njImgs/toolNav.png);
  }
  .NavTwo {
    background-image: url(../../../assets/njImgs/notFouce.png);
  }
  .NavTwo,
  .NavOne {
    width: companyW(190vw);
    height: companyH(50vh);

    background-repeat: no-repeat;

    background-size: cover;
    margin-bottom: companyH(20vh);

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: companyH(4vh) companyW(10vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #fff;
    cursor: pointer;
    .NavTitle {
      margin-left: companyW(10vw);
      font-size: companyW(20vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #fff;
      .NavSpan {
        font-size: companyH(12vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
    .NavTitle2 {
      font-size: companyH(14vh);
      margin-left: companyW(5vw);
      color: #fff;
    }
  }
}

.NavTwo:hover {
  background-image: url('../../../assets/njImgs/xuanfu.png') !important;
  transition: all 0.5s;
}
.el-pagination {
  margin: 0 auto;
  box-sizing: border-box;
  transform: translateX(55%);
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  background: transparent !important;
  background-color: transparent !important;
  border: companyW(1vw) solid #00a8ff !important;
}

::v-deep .el-icon-arrow-left:before {
  color: #fff;
}

::v-deep .el-pagination__total {
  color: #fff;
}

::v-deep .el-pager li {
  background-color: transparent !important;
  // background-color: black;
  color: #fff;
  border: #00a8ff solid 1px;
}
.currentNav {
  opacity: 1 !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(14vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: '#33f8f8' !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}
.flexJt {
  display: flex;
  justify-content: space-between;
}
.littleJt {
  width: companyW(11vw);
  height: companyH(26vh);
  background-image: url(../../../assets/njImgs/dialogImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  margin-top: companyH(10vh);
  margin-left: companyW(7vw);
}
</style>

<template>
  <div class="dry-service">
    <div class="dry-service-echart">
      <Echart :option="option" :actions="click"></Echart>
    </div>
  </div>
</template>

<script>
import Echart from '@/components/echarts/index.vue';
export default {
  props: {
    propOptions: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    Echart
  },
  data () {
    return {
      option: this.propOptions
    };
  },
  methods: {
    click (item) {
      if (item.key === 'click') {
        this.$emit('click',item);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
.dry-service {
  position: relative;
  width: companyW(460vw);
  height: companyH(302vh);
  background: rgba(0, 20, 36, 0.4);
  // opacity: 0.74;
  .dry-service-echart {
    margin: 0 auto;
    margin-top: companyH(20vh);
    width: companyW(430vw);
    height: companyH(300vh);
  }
}
</style>

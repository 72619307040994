<template>
  <div>
    <el-dialog
      :visible.sync="chaMask"
      :show-close="false"
      @close="closeMask"
      @opened="openMask"
      :destroy-on-close="true"
      v-if="chaMask"
    >
      <dv-border-box-1>
        <div class="ToolBox">
          <div class="close" @click="closeMask"></div>
          <slot></slot>
          <div class="NavList" v-if="NavList.length !== 0">
            <div
              class="flexJt"
              v-for="(item, index) in NavList"
              :key="item.id"
              @click="changeNav(index, item.car_type)"
            >
              <div
                :class="
                  index == currentInd
                    ? `${getImg()} currentNav`
                    : `${getImg2()}`
                "
              >
                <div class="NavTitle">{{ item.car_type }}</div>
                <div class="NavTitle2">
                  <span>{{ item.count || 0 }}</span>
                </div>
              </div>
              <div class="littleJt" v-if="currentInd == index"></div>
            </div>
          </div>
          <div v-if="currentId" class="leftDia">
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-if="currentId === 1"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
                v-if="false"

              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="品牌"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="nameplate"
                label="车牌"
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.carLicense"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>
              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="buyDate"
                label="购机时间"
                width="140"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="completedCount"
                label="作业次数（次）"
                width="160"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="completedArea"
                label="作业面积（亩）"
                width="160"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                label="当前状态"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="carPower"
                label="农机动力"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.carPower }}Kw</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="140"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price || 0 }}元</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="allowance"
                label="购机补贴"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.allowance || 0 }}元</span>
                </template>
              </el-table-column>

              <el-table-column label="作业详情" align="center">
                <template v-slot="{ row }">
                  <div
                    style="font-size: 14px; color: blue"
                    @click="goDetail(row)"
                  >
                    查看详情
                  </div>
                </template>
              </el-table-column>

              <div class="closeBtn"></div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="currentId === 2"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="品牌"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <!-- <el-table-column
                prop="nameplate"
                label="铭牌"
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.nameplate"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="carPower"
                label="农机动力"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.carPower }}Kw</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>
              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="buyDate"
                label="购机时间"
                width="140"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="status" label="当前状态" align="center">
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="allowance"
                label="购机补贴"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.allowance }}元</span>
                </template>
              </el-table-column>
              <div class="closeBtn"></div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="currentId === 3"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="品牌"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <!-- <el-table-column
                prop="nameplate"
                label="铭牌"
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.nameplate"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>
              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="buyDate"
                label="购机时间"
                width="140"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="status" label="当前状态" align="center">
              </el-table-column>
              <el-table-column
                prop="carPower"
                label="农机动力"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.carPower }}Kw</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="allowance"
                label="购机补贴"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.allowance }}元</span>
                </template>
              </el-table-column>

              <div class="closeBtn"></div>
            </el-table>

            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="品牌"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <!-- <el-table-column
                prop="nameplate"
                label="铭牌"
                width="120"
                align="center"
              >
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.nameplate"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column> -->

              <el-table-column
                prop="allowance"
                label="购机补贴"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.allowance }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>
              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="buyDate"
                label="购机时间"
                width="140"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="status" label="当前状态" align="center">
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="carPower"
                label="农机动力"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.carPower }}Kw</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else class="leftDia1">
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-if="uid === 1"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>

              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="maintainCount"
                label="维修次数"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="status" label="设备状态" align="center">
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无正常农机
              </div>
            </el-table>

            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="uid === 2"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>

              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>
              <el-table-column
                prop="maintainCount"
                label="维修次数"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="status" label="设备状态" align="center">
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无损坏农机
              </div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="uid === 3"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="price"
                label="农机原值"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>{{ row.price }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>

              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>

              <el-table-column prop="scrapDate" label="报废时间" align="center">
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无报废农机
              </div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="uid === 4"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>

              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>

              <el-table-column prop="status" label="设备状态" align="center">
              </el-table-column>
              <el-table-column label="查看位置" align="center">
                <template>
                  <span @click="openNjMap()">查看位置</span>
                </template>
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无在作业农机
              </div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else-if="uid === 6"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column
                prop="carBrand"
                label="品牌"
                align="center"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>

              <!-- <el-table-column prop="nameplate" label="铭牌" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column> -->

              <el-table-column
                prop="price"
                label="原值"
                width="120 "
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="maintainCount"
                label="维修记录"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="scrapDate" label="报废时间" align="center">
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无待作业农机
              </div>
            </el-table>
            <el-table
              stripe
              v-loading="tabLoading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              :data="TanleContent"
              style="width: 100%"
              height="500"
              v-else
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="picture" label="图片" width="120">
                <template v-slot="{ row }">
                  <el-image
                    style="width: 80px; height: 80px"
                    :src="row.picture"
                    fit="contain"
                  ></el-image>
                </template>
              </el-table-column>

              <el-table-column
                prop="model"
                label="型号"
                width="120 "
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="hasBeidou"
                label="有无北斗"
                align="center"
                width="100"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ row.hasBeidou === true ? '有北斗' : '暂无' }}
                  </span></template
                >
              </el-table-column>

              <el-table-column prop="carOwner" label="所有人" align="center">
              </el-table-column>

              <el-table-column prop="status" label="设备状态" align="center">
              </el-table-column>
              <div slot="empty" style="font-size: 20px; color: #fff">
                暂无近三年新增或报废农机
              </div>
            </el-table>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
    <MaskToolBo
      :visible.sync="visible"
      :TanleContent.sync="TanleContent1"
      :total.sync="total"
      :queryObj="queryObj"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></MaskToolBo>
  </div>
</template>

<script>
import MaskToolBo from './MaskToolDet.vue';

import {
  getIndexNJcount,
  getIndexBFxz,
  getIndexNJState,
  getJobOrder,
  getorderByAgr
} from '@/api/njApi/njzl.js';

export default {
  components: { MaskToolBo },
  props: {
    changeMask: {
      type: Boolean,
      default: false
    },

    NavList: {
      type: Array,
      default: () => []
    },
    TanleContent: {
      type: Array,
      default: () => []
    },
    currentId: {
      type: Number
    },
    uid: {
      type: Number
    },
    currentInd: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tabLoading: true,
      // ...options,
      chaMask: false,
      rateColor: [ '#009ad6' ],
      visible: false,
      TanleContent1: [],
      total: null,
      queryObj: {
        pageSize: 10,
        pageNum: 1
      }
    };
  },
  watch: {
    changeMask: {
      handler (newv, oldv) {
        this.chaMask = newv;
      }
    }
  },

  methods: {
    handleCurrentChange (item) {
      this.queryObj.pageNum = item;
    },
    handleSizeChange (item) {
      this.queryObj.pageSize = item;
    },
    goDetail (item) {
      if (item.carType === '耕整机') {
        this.getJobOr('耕整作业', item.id);
      } else if (item.carType === '插秧机') {
        this.getJobOr('机插作业', item.id);
      } else if (item.carType === '收割机') {
        this.getJobOr('收割作业', item.id);
      } else if (item.carType === '植保机') {
        this.getJobOr('植保作业', item.id);
      } else if (item.carType === '烘干机械') {
        this.getorder('烘干作业', item.id);
      } else if (item.carType === '加工机械') {
        this.getorder('加工作业', item.id);
      }

      this.visible = true;
    },
    async getorder (jobType, id) {
      const { data } = await getorderByAgr({
        jobType,
        id,
        ...this.queryObj
      });
      console.log(data);
      this.TanleContent1 = data.records;

      this.total = data.total;
    },
    async getJobOr (jobType, jobMachineryId) {
      const { data } = await getJobOrder({
        jobType,
        jobMachineryId,
        ...this.queryObj
      });
      console.log(data);
      this.TanleContent1 = data.records;

      this.total = data.total;
    },
    closeMask () {
      this.chaMask = false;

      this.$emit('update:currentInd', null);

      this.$emit('update:uid', null);
      this.$emit('update:currentId', null);
      this.$emit('getMask', false);
      this.$emit('update:TanleContent', []);
    },
    async getIndexNJ (type) {
      if (this.currentId) {
        const { data } = await getIndexNJcount({
          type: 1,
          carType: type,
          unit: 'w'
        });
        this.$emit('update:TanleContent', data);
      }

      if (this.uid === 6) {
        const res = await getIndexBFxz({ status: type });

        this.$emit('update:TanleContent', res.data);
      } else if (this.uid === 1) {
        const res = await getIndexNJState({ status: '正常', carType: type });

        this.$emit('update:TanleContent', res.data);
      } else if (this.uid === 2) {
        const res = await getIndexNJState({ status: '损坏', carType: type });

        this.$emit('update:TanleContent', res.data);
      } else if (this.uid === 3) {
        const res = await getIndexNJState({ status: '报废', carType: type });

        this.$emit('update:TanleContent', res.data);
      } else if (this.uid === 4) {
        const res = await getIndexNJState({ status: '工作', carType: type });

        this.$emit('update:TanleContent', res.data);
      } else if (this.uid === 5) {
        const res = await getIndexNJState({ status: '空闲', carType: type });

        this.$emit('update:TanleContent', res.data);
      } else return;
    },
    changeNav (id, type) {
      this.tabLoading = true;
      // this.currentInd = id;
      this.$emit('update:currentInd', id);

      this.getIndexNJ(type);
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },
    openMask () {},
    getImg () {
      if (this.uid === 1) return 'NavOne';
      else if (this.uid === 2) return 'NavOne1';
      else if (this.uid === 3) return 'NavOne2';
      else if (this.uid === 4) return 'NavOne3';
      else if (this.uid === 5) return 'NavOne4';
      else if (this.uid === 6) return 'NavOne5';
      else if (
        this.currentId === 3 ||
        this.currentId === 4 ||
        this.currentId === 2
      )
        return 'NavOne7';
      else if (this.currentId) return 'NavOne';
      else return;
    },
    getImg2 () {
      if (this.uid === 1) return 'NavTwo';
      else if (this.uid === 2) return 'NavTwo1';
      else if (this.uid === 3) return 'NavTwo2';
      else if (this.uid === 4) return 'NavTwo3';
      else if (this.uid === 5) return 'NavTwo4';
      else if (this.uid === 6) return 'NavTwo5';
      else if (
        this.currentId === 3 ||
        this.currentId === 4 ||
        this.currentId === 2
      )
        return 'NavTwo7';
      else if (this.currentId) return 'NavTwo';
      else return;
    }
  },

  created () {},
  updated () {},
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(1700vw);
  background: #000000;
  // opacity: 0.9;
  background: rgba($color: #000000, $alpha: 0.9);
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-table th.el-table__cell > .cell {
  color: #fff;
  font-size: companyW(18vw);
}
.ToolBox {
  width: 95%;

  // margin: 0 auto;
  padding: companyH(88vh) 0;
  display: flex;
  .leftDia {
    width: 55%;
  }
  .leftDia1 {
    width: 50%;
  }
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
  .NavList {
    display: flex;
    flex-direction: column;
    margin-right: companyW(30vw);
    .NavOne {
      background-image: url(../../../assets/njImgs/toolNav.png);
    }
    .NavOne1 {
      background-image: url(../../../assets/njImgs/toolNav_orange.png);
    }
    .NavOne2 {
      background-image: url(../../../assets/njImgs/toolNav_white.png);
    }
    .NavOne3 {
      background-image: url(../../../assets/njImgs/toolNav_green.png);
    }
    .NavOne4 {
      background-image: url(../../../assets/njImgs/toolNav_blue.png);
    }
    .NavOne5 {
      background-image: url(../../../assets/njImgs/toolNav_puple.png);
    }

    .NavOne7 {
      background-image: url(../../../assets/njImgs/toolNav.png);

      width: companyW(220vw);
      height: companyH(50vh);

      background-repeat: no-repeat;

      background-size: cover;
      margin-bottom: companyH(15vh);

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      opacity: 0.5;
      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
    .NavTwo {
      background-image: url(../../../assets/njImgs/notFouce.png);
    }
    .NavTwo1 {
      background-image: url(../../../assets/njImgs/notFouce_orange.png);
    }
    .NavTwo2 {
      background-image: url(../../../assets/njImgs/notFouce_white.png);
    }
    .NavTwo3 {
      background-image: url(../../../assets/njImgs/notFouce_green.png);
    }
    .NavTwo4 {
      background-image: url(../../../assets/njImgs/notFouce_blue.png);
    }
    .NavTwo5 {
      background-image: url(../../../assets/njImgs/notFouce_puple.png);
    }
    .NavTwo7 {
      background-image: url(../../../assets/njImgs/notFouce.png);

      width: companyW(220vw);
      height: companyH(50vh);

      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: companyH(15vh);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;

      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyH(20vh);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
    .NavOne,
    .NavOne1,
    .NavOne2,
    .NavOne3,
    .NavOne4,
    .NavOne5,
    .NavTwo,
    .NavTwo1,
    .NavTwo2,
    .NavTwo3,
    .NavTwo4,
    .NavTwo5 {
      width: companyW(200vw);
      height: companyH(50vh);

      background-repeat: no-repeat;

      background-size: cover;
      margin-bottom: companyH(15vh);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: companyH(4vh) companyW(10vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #33f8f8;
      cursor: pointer;
      .NavTitle {
        margin-left: companyW(10vw);
        font-size: companyW(20vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #fff;
        .NavSpan {
          font-size: companyH(16vh);
          margin-left: companyW(5vw);
          color: #33f8f8;
        }
      }
      .NavTitle2 {
        font-size: companyH(14vh);
        margin-left: companyW(5vw);
        color: #fff;
      }
    }
  }
}
.NavTwo:hover {
  background-image: url('../../../assets/njImgs/xuanfu.png') !important;
}
.NavTwo1:hover {
  background-image: url('../../../assets/njImgs/xuanfu_orange.png') !important;
}
.NavTwo2:hover {
  background-image: url('../../../assets/njImgs/xuanfu_white.png') !important;
}
.NavTwo3:hover {
  background-image: url('../../../assets/njImgs/xuanfu_green.png') !important;
}
.NavTwo4:hover {
  background-image: url('../../../assets/njImgs/xuanfu_blue.png') !important;
}
.NavTwo5:hover {
  background-image: url('../../../assets/njImgs/xuanfu_puple.png') !important;
}
.NavTwo7:hover {
  background-image: url('../../../assets/njImgs/xuanfu.png') !important;
}
.currentNav {
  opacity: 1 !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(18vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: '#33f8f8' !important;
  font-size: companyH(14vh);
  text-decoration: underline;
  cursor: pointer;
}
.flexJt {
  display: flex;
  justify-content: space-between;
}
.littleJt {
  width: companyW(11vw);
  height: companyH(26vh);
  background-image: url(../../../assets/njImgs/dialogImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  margin-top: companyH(5vh);
  margin-left: companyW(7vw);
}
</style>

<template>
  <div>
    <el-dialog
      :visible="visible"
      :show-close="false"
      @close="closenjs"
      v-if="visible"
    >
      <dv-border-box-1>
        <div class="ToolBox">
          <div class="title">已完成订单</div>
          <div class="close" @click="closenjs"></div>
          <div class="TabNav">
            <div class="NavButton" v-for="item in TabNav" :key="item.id">
              <div
                :class="
                  currentId === item.id ? 'currentNav NavButton' : 'NavButton'
                "
                @click="changeNav(item.id, item.name)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>

          <el-table
            stripe
            v-loading="tabLoading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :data="TanleContent"
            style="width: 100%"
            height="500"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column prop="farmerName" label="作业对象" align="center">
              <template v-slot="{ row }">
                <div>{{ row.farmerName }}-{{ row.jobFieldName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="farmerTel" label="农户电话" align="center" width="150">
            </el-table-column>
            <el-table-column
              prop="jobTractorName"
              label="操作手"
              align="center"
              width="120"
            >
            </el-table-column>


              <el-table-column prop="jobPrice" label="作业价格" align="center"  >
              <template v-slot="{ row }">
                <div>{{ row.jobPrice }}元</div></template
              >
            </el-table-column>
            <el-table-column
              prop="jobNum"
              label="作业面积"
              width="120 "
              align="center"
            >
              <template v-slot="{ row }">
                <div>{{ row.jobNum }}亩</div></template
              >
            </el-table-column>
            <el-table-column label="作业时间" width="350" align="center">
              <template v-slot="{ row }">
                <div>
                  {{ row.orderStartTime }}-{{
                    row.orderFinishTime.substr(11)
                  }}
                </div></template
              >
            </el-table-column>

            <el-table-column prop="jobWay" label="作业路径" align="center" >
              <template v-slot="{ row }">
                <div style="font-size: 16px; color: #279cff" @click="open(row)">
                  查看路径
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderEvaluate"
              label="综合评价"
              align="center"
              width="140"
            >
              <template v-slot="{ row }">
                <el-rate
                  v-model="row.orderEvaluate"
                  :disabled="true"
                  :colors="['#009ad6', '#009ad6', '#009ad6']"
                  disabled-void-color="#77787b"
                ></el-rate>
              </template>
            </el-table-column>
            <div slot="empty" style="font-size: 20px; color: #fff">
              暂无已完成订单
            </div>
          </el-table>
        </div>

        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryObj.pageNum"
          :page-size="queryObj.pageSize"
          layout="total,prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </dv-border-box-1>
    </el-dialog>

    <mapModel
      :mspMask="mspMask"
      v-on="$listeners"
      @closeMapMask="closeMapMask"
      :mapInfo="mapInfo"
    >
    </mapModel>
  </div>
</template>

<script>
import mapModel from './njdd/mapMode.vue';
export default {
  components: { mapModel },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    TanleContent: {
      type: Array,
      default: () => [],
      require: true
    },
    total: {
      type: Number
    },
    queryObj: {
      type: Object
    }
  },
  data () {
    return {
      mspMask: false,
      TabNav: [
        { id: 0, name: '全部', value: '全部' },
        { id: 1, name: '耕整作业', value: 'gzzy' },
        { id: 2, name: '机插作业', value: 'jxzy' },
        { id: 3, name: '机收作业', value: 'jszy' },
        { id: 4, name: '植保作业', value: 'zbzy' }
      ],

      tabLoading: false,
      currentId: 0,
      currentName: '',
      mapInfo: {}
    };
  },
  methods: {
    open (row) {
      this.mapInfo = row;
      this.mspMask = true;
    },
    closeMapMask () {
      this.mspMask = false;
      this.mapInfo = null;
    },
    closenjs () {
      this.$emit('update:visible', false);
      this.$emit('update:visible', false);
      this.$emit('update:total', null);
    },

    changeNav (id, name) {
      this.currentId = id;
      this.currentName = name;
      if (name === '耕整作业') {
        this.$emit('getFinOrder', '已完成', '耕整作业');
      } else if (name === '机插作业') {
        this.$emit('getFinOrder', '已完成', '机插作业');
      } else if (name === '机收作业') {
        this.$emit('getFinOrder', '已完成', '机收作业');
      } else if (name === '植保作业') {
        this.$emit('getFinOrder', '已完成', '植保作业');
      } else {
        this.$emit('getFinOrder', '已完成');
      }
      setTimeout(() => {
        this.tabLoading = false;
      }, 1000);
    },

    handleSizeChange (val) {
      console.log(`每页 ${ val } 条`);
      this.$emit('handleSizeChange', val, this.currentName);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${ val }`);
      this.$emit('handleCurrentChange', val, this.currentName);
    }
  },

  created () {},
  mounted () {
    setTimeout(() => {
      this.tabLoading = false;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
::v-deep .el-dialog {
  width: companyW(1600vw);
  background: #000000;
  opacity: 1;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left;
}
::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .cell {
  font-size: companyW(20vw);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table td.el-table__cell {
  font-size: companyW(20vw);
}
.ToolBox {
  width: 95%;
  // height: companyH(605vh);
  margin: 0 auto;
  padding: companyH(88vh) 0;
  .title {
    position: absolute;
    top: companyH(20vh);
    left: 45%;
    font-size: companyW(24vw);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1dfcff;
  }
  .TabNav {
    display: flex;
    margin-bottom: companyH(20vh);
    .NavButton {
      text-align: center;
      color: #fff;
      font-size: companyW(18vw);

      margin-right: companyW(30vw);
      width: companyW(100vw);
      height: companyH(40vh);
      line-height: companyH(40vh);
      background: rgba(12, 73, 79, 0.5);
    }
    .currentNav {
      background: rgba(55, 57, 66, 0.54);
      border: 1px solid #009bed;
      font-size: companyW(18vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: companyH(40vh);
      color: #009bed;
    }
  }

  .leftDia {
    width: 55%;
  }
  .leftDia1 {
    width: 50%;
  }
  .close {
    width: companyW(20vw);
    height: companyW(20vw);
    background: url('../../../assets/njImgs/closeDia.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
  }
}

.el-pagination {
  margin: 0 auto;
  box-sizing: border-box;

  margin-left: companyW(600vw);
}

::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  background: transparent !important;
  background-color: transparent !important;
  border: companyW(1vw) solid #00a8ff !important;
}

::v-deep .el-icon-arrow-left:before {
  color: #fff;
}

::v-deep .el-pagination__total {
  color: #fff;
}

::v-deep .el-pager li {
  background-color: transparent !important;
  // background-color: black;
  color: #fff;
  border: #00a8ff solid 1px;
}
.currentNav {
  opacity: 1 !important;
}
.littleJt {
  width: companyW(11vw);
  height: companyH(26vh);
  background-image: url(../../../assets/njImgs/dialogImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  opacity: 1 !important;
}

::v-deep .el-table th {
  background: #1b4069;
  border-top: companyW(1vw) solid #1b4069;
  border-bottom: companyW(1vw) solid #1b4069;
  height: companyH(35vh);
  padding: 0;
  text-align: center;
}
::v-deep .cell {
  font-size: companyH(20vh);
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-table td,
.building-top .el-table th.is-leaf {
  border-bottom: 1px solid rgba(238, 238, 238, 0);
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #71cef916;
  // opacity: 0.1;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-table tr,
::v-deep .el-table::before,
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #000000;
}
.toolStyle {
  color: '#33f8f8' !important;

  text-decoration: underline;
  cursor: pointer;
}
</style>
